import React from 'react';
import Bouton from '../components/Bouton';
import { useAuth } from '../providers/AuthProvider';
import { APP_ROUTES } from '../utils/constants';

function Gestion() {
  const {logout, hasLoginData} = useAuth();

	return (
    <div className="acceuil">
      <Bouton  nom="Article" type="lien" lien={APP_ROUTES.ARTICLE}  />
      <Bouton  nom="Theme" type="lien" lien={APP_ROUTES.THEME}  />
      <Bouton  nom="IndividuMail" type="lien" lien={APP_ROUTES.INDIVIDUMAIL}  />
      
      <p>Bienvenue</p>      
      {hasLoginData() ?
        <Bouton nom="Déconnexion" type="onClick" callback={logout} ></Bouton>
      :
        <Bouton nom="Connexion" type="lien" lien={APP_ROUTES.LOGIN}  />
      }
    </div>
    );
}

export default Gestion;