import './App.css';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Gestion from "./pages/Gestion"
import FormulaireLogin from "./components/FormulaireLogin";
import { AuthProvider } from "./providers/AuthProvider";
import ScrollToTop from "./utils/ScrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from './utils/constants';

import RequireAuth from './components/RequireAuth';
import RequireVerification from './components/RequireVerification';
import GestionArticle from "./components/GestionArticle"
import GestionTheme from "./components/GestionTheme"
import GestionIndividuMail from "./components/GestionIndividuMail"
import NavBar from "./components/Navbar/NavBar"
import Accueil from './pages/Accueil';
import Inscription from './pages/Inscription';
import VerificationReussie from "./pages/VerificationReussie"
import VerificationErreur from "./pages/VerificationErreur"
import APropos from "./pages/APropos"

function App() {

  const[width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange(){
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // gestion de resize de l'appli
    window.addEventListener('resize', handleWindowSizeChange);
    return() => {
      window.removeEventListener('resize',handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
          <ScrollToTop />
          <AuthProvider>
            <NavBar />
            <Routes>
                <Route path="/" element={<Accueil width={width} />}/>
                <Route path="/politique-confidentialite" element={<Accueil width={width} isModalOpen={true} />}/>
                <Route path={APP_ROUTES.INSCRIPTION} element={<Inscription />}/>
                <Route path={APP_ROUTES.LOGIN} element={<FormulaireLogin />}/>
                <Route element={<RequireAuth />}>  
                  <Route path="/gestion" element={<Gestion />}/>
                  <Route path={APP_ROUTES.ARTICLE} element={<GestionArticle />}/>
                  <Route path={APP_ROUTES.THEME} element={<GestionTheme />}/>
                  <Route path={APP_ROUTES.INDIVIDUMAIL} element={<GestionIndividuMail />}/>
                </Route>
                <Route element={<RequireVerification />}>  
                  <Route path={APP_ROUTES.VERIFICATION} element={<VerificationReussie width={width} />}/>
                </Route>
                <Route path={APP_ROUTES.ERREURVERIFMAIL} element={<VerificationErreur />}/>
                <Route path={APP_ROUTES.APROPOS} element={<APropos />}/>

                {/*si n'importe quoi dans l'url on redirige vers home */}
                <Route path="/*" element={<Accueil />}/>
            </Routes>
          </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
