import React, { useState } from 'react';
import { Box, Card, CardBody, Heading, Stack, Input, Button, Checkbox, Text, Center, Link as ChakraLink, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import individuMailApi from '../api/individu';
import PolitiqueConfidentialite from './PolitiqueConfidentialite';

function EmailCard(props) {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const createIndividuMail = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await individuMailApi.postIndividuMail(data);
      setSubmissionMessage(response.message || 'Votre email a été enregistré avec succès !');
      setIsSuccess(true);
    } catch (error) {
      setSubmissionMessage('Erreur : Impossible d\'enregistrer votre email.');
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { email, consent };
    createIndividuMail(data);
  };

  return (
    <Box
      borderRadius="md"
      overflow="hidden"
      position="relative"
      maxW="1000px"
      w={["87vw", "sm", "md", "xl"]}
      ml={["sm", "sm", "", ""]}
      mr={["sm", "sm", "", ""]}
      _hover={{
        transform: 'scale(1.05)',
        transition: 'transform 0.6s ease-in-out',
        boxShadow: '0 0 15px 6px rgba(30, 150, 255, 0.4)',
      }}
    >
      <Card
        direction="column"
        overflow='hidden'
        variant='outline'
        bg="linear-gradient(90deg, rgba(30,150,255,1) 0%, rgba(30,150,255,0.8) 50%, rgba(30,150,255,0.6) 100%)"
        filter={isSuccess ? 'blur(5px)' : 'none'}
      >
        <Stack
          position="relative"
          zIndex="1"
          w="100%"
          h="full"
        >
          <CardBody>
            <Heading textAlign="left" color="white" fontSize="lg" overflow="hidden">
              {props.description}
            </Heading>
            <form onSubmit={handleSubmit}>
              <Input
                placeholder="Entrez votre e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mt={4}
                required
              />
              <Box mt={2} ml="5" display="flex" alignItems="center">
                <Checkbox
                  colorScheme="blue"
                  isChecked={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                  sx={{
                    '.chakra-checkbox__control': {
                      width: '20px', // Custom size
                      height: '20px', // Custom size
                      padding: '5px', // Thicker border
                    },
                    '.chakra-checkbox__label': {
                      fontSize: 'lg', // Larger label text if needed
                      mr: 2,
                    },
                  }}
                />
                <Text color="white" textAlign="left" ml={2}>
                  En cochant cette case, je confirme avoir lu la {' '}
                  <ChakraLink
                    textDecoration="underline"
                    color="white"
                    onClick={() => setIsModalOpen(true)}
                    cursor="pointer"
                  >
                    politique de confidentialité
                  </ChakraLink>
                  {' '} de RappelVote.
                </Text>
              </Box>
              <Button
                type="submit"
                colorScheme="blue"
                mt={4}
                isDisabled={!consent || isSubmitting}
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Stack>
      </Card>

      {isSuccess && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0, 0, 0, 0.6)"
          zIndex="10"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            p={5}
            borderRadius="md"
            border="4px solid rgba(30, 150, 255, 1)"
            textAlign="center"
            w="100%"
            h="100%"
          >
            <Text fontSize="2xl" fontWeight="bold" color="white">
              Merci pour votre inscription, une dernière étape!
            </Text>
            <Text fontSize="lg" mt="8" color="white">
              Veuillez vérifier votre boîte mail pour confirmer votre adresse. Nous vous avons envoyé un lien de confirmation.
            </Text>
            <Text fontSize="lg" mt="8" color="white">
              Une fois vérifié, vous recevrez un rappel pour les élections.
            </Text>
          </Box>
        </Box>
      )}
      <PolitiqueConfidentialite isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Box>
  );
}

export default EmailCard;
