import React from 'react';
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

function PolitiqueConfidentialite({ isModalOpen, setIsModalOpen }) {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent maxWidth="80%" width="80%">
        <ModalHeader>Politique de confidentialité</ModalHeader>
        <br />
        <ModalCloseButton />
        <ModalBody>
          <Text as="h2" fontWeight="bold" mb={4}>1. Introduction</Text>
          <Text mb={4}>
            Bienvenue sur RappelVote ("nous", "notre" ou "nos"). Nous prenons au sérieux la confidentialité de nos utilisateurs ("vous" ou "votre"). Cette Politique de Confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez notre site web https://rappelvote.fr ou nos services (collectivement, les "Services").
          </Text>
          <br />
          
          <Text as="h2" fontWeight="bold" mb={4}>2. Informations que nous collectons</Text>
          <Text mb={4}>
            Nous collectons plusieurs types d'informations, y compris :
            <ul>
              <li><b>Informations d'identification</b> : Adresse e-mail, numéro de téléphone.</li>
              <li><b>Données de navigation</b> : Adresse IP, type de navigateur, langue du navigateur, pages visitées, heure de la visite et autres informations sur les interactions avec notre site.</li>
              <li><b>Cookies et technologies similaires</b> : Nous utilisons des cookies pour améliorer votre expérience sur notre site et pour collecter des informations sur votre utilisation de nos Services.</li>
            </ul>
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>3. Utilisation des cookies</Text>
          <Text mb={4}>
            Les cookies sont de petits fichiers texte placés sur votre appareil pour collecter des informations standard de journal Internet et des informations sur le comportement des visiteurs. Lorsque vous visitez notre site, nous pouvons collecter des informations vous concernant automatiquement via des cookies ou des technologies similaires.
          </Text>
          <Text mb={4}>
            <b>Types de cookies que nous utilisons :</b>
            <ul>
              <li><b>Cookies essentiels</b> : Ces cookies sont nécessaires pour le fonctionnement de notre site.</li>
              <li><b>Cookies analytiques</b> : Utilisés pour analyser l'utilisation du site afin d'améliorer nos services.</li>
              <li><b>Cookies de ciblage/publicité</b> : Utilisés pour vous proposer des publicités pertinentes pour vous.</li>
            </ul>
          </Text>
          <br />
          <Text mb={4}>
            Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur. Cependant, le refus des cookies peut affecter votre utilisation de certaines fonctionnalités de notre site.
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>4. Utilisation de vos informations</Text>
          <Text mb={4}>
            Nous utilisons les informations que nous collectons pour :
            <ul>
              <li>Fournir et améliorer nos services.</li>
              <li>Communiquer avec vous, notamment pour des raisons de support client.</li>
              <li>Vous envoyer des informations promotionnelles sur nos produits ou services.</li>
              <li>Personnaliser votre expérience sur notre site.</li>
              <li>Respecter les obligations légales.</li>
            </ul>
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>5. Partage de vos informations</Text>
          <Text mb={4}>
            Nous pouvons partager vos informations dans les circonstances suivantes :
            <ul>
              <li><b>Avec des partenaires tiers</b> : Pour des raisons de marketing ou pour fournir et améliorer nos services.</li>
              <li><b>Avec des fournisseurs de services</b> : Qui traitent les données en notre nom.</li>
              <li><b>Pour se conformer aux lois</b> : Lorsque nous sommes légalement tenus de le faire.</li>
            </ul>
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>6. Sécurité de vos informations</Text>
          <Text mb={4}>
            Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre la perte, le vol, l'accès non autorisé, la divulgation, la copie, l'utilisation ou la modification. Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est entièrement sécurisée. Par conséquent, nous ne pouvons garantir une sécurité absolue.
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>7. Vos droits</Text>
          <Text mb={4}>
            Selon les lois en vigueur, vous pouvez avoir les droits suivants concernant vos informations personnelles :
            <ul>
              <li><b>Accès</b> : Demander une copie des informations que nous détenons à votre sujet.</li>
              <li><b>Correction</b> : Demander la correction de toute information inexacte ou incomplète.</li>
              <li><b>Suppression</b> : Demander la suppression de vos informations personnelles.</li>
              <li><b>Objection</b> : S'opposer au traitement de vos informations personnelles.</li>
              <li><b>Retrait du consentement</b> : Retirer votre consentement à tout moment.</li>
            </ul>
            Pour exercer ces droits, veuillez nous contacter à l'adresse [adresse e-mail de contact].
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>8. Modifications de cette Politique de Confidentialité</Text>
          <Text mb={4}>
            Nous pouvons mettre à jour cette Politique de Confidentialité de temps en temps. Nous vous informerons de toute modification en publiant la nouvelle Politique de Confidentialité sur cette page et en mettant à jour la date d'entrée en vigueur en haut de cette page. Nous vous encourageons à consulter cette Politique régulièrement pour rester informé de la manière dont nous protégeons vos informations.
          </Text>
          <br />

          <Text as="h2" fontWeight="bold" mb={4}>9. Nous contacter</Text>
          <Text mb={4}>
            Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité ou nos pratiques de traitement des données, veuillez nous contacter à l'adresse suivante :
          </Text>
          <Text mt={4}>
            <ul>
              <li><b>Adresse e-mail</b> : matvey.sienichev@gmail.com</li>
            </ul>
          </Text>
          <br />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PolitiqueConfidentialite;
