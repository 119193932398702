import React, {useState} from 'react';
import WideCard from '../components/WideCard';
import EmailCard from '../components/EmailCard';
import LandingDiorama from '../components/LandingDiorama';
import { useAuth } from '../providers/AuthProvider';
import { Text, Box, Heading, Container, Stack, Flex, Button, Link as ChakraLink } from '@chakra-ui/react';
import FacebookPostButton from '../components/FacebookShareButton';
import TwitterPostButton from '../components/TwitterShareButton';
import Footer from '../components/Footer';

function Accueil({width, isModalOpen}) {
	return (
    <div className="accueil">
      <Box position="relative" width="100%" height={["70vh", "80vh", "92vh", "92vh"]} >
        <LandingDiorama width={width} />
        <Box position="absolute" top="0" left={["7%","7%","7%","20%"]} right="0" bottom="20%" display="flex" flexDirection="column" alignItems="left" justifyContent="center" color="white" zIndex="10">
          <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} textAlign="left">Votre voix compte!</Heading>
          <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} textAlign="left">N'agissez pas au dernier moment</Heading>
          <Heading size='md' mt="4" fontFamily="heading" fontSize={["md","xl","2xl","2xl"]} textAlign="left">Nous vous proposons des mails de rappel pour aller voter le 30 Juin et 7 Juillet 2024</Heading>
          <Box display="flex" mt="4" fontSize={["sm","sm","md","xl"]} alignItems="left">
            <ChakraLink href="/inscription" _hover={{ textDecoration: 'none' }}>
              <Button colorScheme="cyan" >
                Je m'inscris
              </Button>
            </ChakraLink>
          </Box>
        </Box>
      </Box>
      <Flex alignItems="center" justifyContent="center" >
        <Heading mb="4">
          Que se passe-t-il le 30 Juin et 7 Juillet?         
        </Heading>
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <WideCard description="Ces dates marquent le déroulement des élections législatives, convoquées suite à la dissolution de l'Assemblée nationale par le président Emmanuel Macron. Cette décision intervient après les élections européennes où le Rassemblement National a obtenu un score significatif, lançant ainsi un processus électoral pour redéfinir la composition politique de l'Assemblée nationale." />
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <Heading mt="16" mb="4">
          Pourquoi voter?          
        </Heading>
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <WideCard description="En exprimant votre choix lors des élections, vous contribuez à déterminer qui vous représentera et prendra des décisions en votre nom. C'est une manière de faire entendre votre voix, de façonner l'avenir du pays et de participer à la construction d'une société qui reflète vos valeurs et vos aspirations. En somme, voter est un acte de citoyenneté et de responsabilité qui vous permet de défendre vos intérêts et de contribuer au bien-être collectif." />
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <Heading mt="16" mb="4">
          Rappel de vote          
        </Heading>
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <EmailCard description="Pour sensibiliser à l'exercice démocratique, RappelVote vous propose des rappels pour aller voter le 30 Juin et le 7 Juillet 2024." />
      </Flex>
      <br />
      <br />
      <br />
      <Flex 
        mt="4" 
        gap="8" 
        flexDirection={['column', 'column', 'row', 'row']} // Vertical on small screens, horizontal on large screens
        justifyContent={"center"}
        alignItems={'center'} // Centered on small screens, start aligned on larger screens
      >
        <FacebookPostButton />
        <TwitterPostButton />
      </Flex>
      <br />
      <br />
      <br />
      {
        isModalOpen === undefined ?
        <Footer />
        :
        <Footer isModalOpened={isModalOpen} />
      }
    </div>
    );
}

export default Accueil;