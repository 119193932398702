import React, {useState} from 'react';
import TextEmoji from '../components/TextEmoji';
import { Text, Box, Heading, Container, Stack, Flex, Button, Link as ChakraLink } from '@chakra-ui/react';
import PolitiqueConfidentialite from '../components/PolitiqueConfidentialite';

function Footer({width, isModalOpened}) {
  const [isModalOpen, setIsModalOpen] = useState(isModalOpened);
  return(
      <Box width="100%">
        <Flex 
          direction={['column', 'row']} // Colonne sur petits écrans, ligne sur les grands
          justifyContent="space-between"
          alignItems="center" // Aligner tous les éléments verticalement au centre
          width="100%"
        >
          <Text fontSize="md" color="white.500">
            <ChakraLink
              textDecoration="underline"
              color="white"
              onClick={() => setIsModalOpen(true)}
              cursor="pointer"
            >
              Politique de confidentialité
            </ChakraLink>
          </Text>
          <Text pt="2" fontSize="md" color="white.500">Made with <TextEmoji emoji="red_heart" />  in Brest, France  <TextEmoji emoji="flag_france" /></Text>
          <Text pt="2" fontSize="md" color="white.500">emojis by 3moji.org</Text>
        </Flex>
        <PolitiqueConfidentialite isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Box>
  );
}

export default Footer;