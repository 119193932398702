import React from 'react';
import { Box, Heading, Button, Link as ChakraLink } from '@chakra-ui/react';
import Footer from '../components/Footer';

function VerificationErreur() {
	return (
    <div className="accueil">
      <Box position="relative" width="100%" height={["70vh", "80vh", "92vh", "92vh"]} >
        <Box position="absolute" top="0" left="20%" right="0" bottom="20%" display="flex" flexDirection="column" alignItems="left" justifyContent="center" color="white" zIndex="10">
          <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} textAlign="left">Votre adresse a déjà été validée.</Heading>
          <Heading size='md' mt="4" fontFamily="heading" fontSize={["md","xl","2xl","3xl"]} textAlign="left">Veuillez revenir en arrière</Heading>
          <Box display="flex" mt="4" fontSize={["sm","sm","md","xl"]} alignItems="left">
            <ChakraLink href="/" _hover={{ textDecoration: 'none' }}>
              <Button colorScheme="cyan" >
                Retour à l'accueil
              </Button>
            </ChakraLink>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
    );
}

export default VerificationErreur;