import React from 'react';
import './Gestion.css';
import deleteIcon from '../assets/delete.png'
import Bouton from './Bouton';
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";

import useApi from '../hooks/useApi';
import individuMailApi from '../api/individu'; //Import the API service function
import { convertDateHuman, convertDateSql } from '../utils/dateConverter'; // Import the API service function

const ROW_AJOUT = 1;
function GestionIndividuMails(props) {
  const [editMode, setEditMode] = useState([]);
  
  const [dateInscription, setDateInscription] = useState(new Date());
  
  const getIndividuMailsApi = useApi(individuMailApi.getIndividuMails);
  
  useEffect(() => { // fetch des données 
    getIndividuMailsApi.request(); 
    // eslint-disable-next-line
  }, []);

  useEffect(() => { // intialisation des valeurs d'état pour l'interface
    if (!getIndividuMailsApi.loading && getIndividuMailsApi.data) {
      var tabEditMode = new Array(getIndividuMailsApi.data.length + ROW_AJOUT).fill(false);
      setEditMode(tabEditMode);
    }
    // eslint-disable-next-line
  }, [getIndividuMailsApi.data]); 

  const handleSubmit = event => {
    event.preventDefault();
    const id = Number.parseInt(window.event.submitter.id);
    const data = {
      pk_idIndividu: id,
      email: event.target.email.value,
      aVote: event.target.mdp.value,
      dateInscription: convertDateSql(dateInscription)
    }
    //check submit type:
    if(window.event.submitter.name === "CREATE"){
      createIndividuMail(data);
    }else if(window.event.submitter.name === "EDIT"){
      editIndividuMail(id, data);
    }
    //reset affichage du tableau apres envoi.
    var tabEditMode = new Array(editMode.length).fill(false);
    setEditMode(tabEditMode);
  }

  const createIndividuMail = async (data) => {
    try {
      await individuMailApi.postIndividuMail(data);
      getIndividuMailsApi.request();
    } catch (error) {
    }
  }
  
  const editIndividuMail = async (id, data) => {
    try {
      await individuMailApi.putIndividuMail(id, data);
      getIndividuMailsApi.request();
    } catch (err) {
      console.error(err);
    }
  }
  
  const removeIndividuMail = async (id) => {
    const choice = window.confirm("Êtes vous sûr de vouloir supprimer ce individuMail?\n\n\nUne fois supprimé tout les inscrits seront enlevés et il ne pourra plus être récupéré.");
    if (!choice) return;
    try {
      await individuMailApi.deleteIndividuMail(id);
      getIndividuMailsApi.request();
    } catch (error) {
    }
  }
  
  const annuler = () => {//reinitialise les champs d'edition.
    var tabEditMode = new Array(editMode.length).fill(false);//reset affichage du tableau apres envoi.
    setEditMode(tabEditMode);        
  }

	return (
    <div className="EspaceGestion">
      {/* <div className="EspaceIndividuMails"> */}
      <Bouton  nom="Gestion" type="lien" lien={"/gestion"}  />
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className='tl-th th-visible'>pk_idindividu</th>
              <th className='th-visible'>email</th>
              <th className='th-visible'>valide</th>
              <th className='th-visible'>aVote</th>
              <th className='tr-th th-visible'>dateInscription</th>
              <th className='th-visible'>autorisationReventeEmail</th>
              <th className='th-visible'>autorisationReventeTelephone</th>
            </tr>
          </thead>
          <tbody>
            {getIndividuMailsApi.data?.map( (individuMail, n) => ( 
              <tr key={"tabgestion-"+n} className="tr-visible"> 
                <td>{ editMode[n] ?
                  <img src={deleteIcon} className="gestion-delete-icon" alt="" onClick={() => removeIndividuMail(individuMail.pk_idIndividuMail)} />
                  :
                  null }
                </td>
                <td className='td-visible'>{ editMode[n] ?
                  <>
                    <input type="number" id="pk_idindividu" name="pk_idIndividuMail" defaultValue={individuMail.pk_idindividu}></input>
                  </>
                :
                  individuMail.pk_idindividu }</td>
                <td className='td-visible'>{ editMode[n] ?
                  <select name="avote" defaultValue={individuMail.contactinfo}>
                    <option value="true" >oui</option>
                    <option value="false" >non</option>
                  </select>
                :
                  individuMail.contactinfo}</td>
                <td className='td-visible'>{ editMode[n] ?
                  <select name="avote" defaultValue={individuMail.valide}>
                    <option value="true" >oui</option>
                    <option value="false" >non</option>
                  </select>
                :
                  individuMail.valide ? "oui" : "non" }</td>
                <td className='td-visible'>{ editMode[n] ?
                  <select name="avote" defaultValue={individuMail.avote}>
                    <option value="true" >oui</option>
                    <option value="false" >non</option>
                  </select>
                :
                  individuMail.avote ? "oui" : "non" }</td>
                <td className='td-visible'>{ editMode[n] ?
                  <>
                  <DatePicker selected={dateInscription} dateFormat="dd/MM/yyyy" onChange={(date) => setDateInscription(date)} />
                  </>
                :
                  individuMail.dateinscription }</td>
                <td className='td-visible'>{ editMode[n] ?
                  <select name="avote" defaultValue={individuMail.autorisationreventeemail}>
                    <option value="true" >oui</option>
                    <option value="false" >non</option>
                  </select>
                :
                  individuMail.autorisationreventeemail ? "oui" : "non" }</td>
                <td className='td-visible'>{ editMode[n] ?
                  <select name="avote" defaultValue={individuMail.autorisationreventetelephone}>
                    <option value="true" >oui</option>
                    <option value="false" >non</option>
                  </select>
                :
                  individuMail.autorisationreventetelephone ? "oui" : "non" }</td>
                <td>{editMode[n] ?
                  <>
                    <input type="submit" name="EDIT" value="Envoyer" id={`${individuMail.pk_idIndividuMail}`}  />
                    <button className='gestion-form-annuler' onClick={() => {annuler()}} >Annuler</button>
                  </>
                    :
                  <div onClick={ () => {
                    // setDateDebutIndividuMail(new Date(individuMail.date1IndividuMail)); setDateFinIndividuMail(new Date(individuMail.date2IndividuMail))
                    }} >
                    <Bouton nom="Modifier" type="editMode" editMode={editMode} i={n} setEditMode={setEditMode}  ></Bouton>
                  </div>
                }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default GestionIndividuMails;