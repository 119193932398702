import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../providers/AuthProvider';
import { useParams } from "react-router-dom";
import { APP_ROUTES } from '../utils/constants';
import individuApi from '../api/individu'; 
import useApi from '../hooks/useApi';

const RequireVerification = () => {
    const location = useLocation();
    const verificationMailApi = useApi(individuApi.verificationMail);
    const [verified, setVerified] = useState(null);
    const { token } = useParams();
  
    const verif = async () => {
        const response = await verificationMailApi.request(token);
        return response?.data;
    };

    useEffect(() => {
        const fetchData = async () => {
                const response = await verif().then((r) => {
                    console.log(r);
                    if (
                        r.data !== null &&
                        !verificationMailApi?.error &&
                        !verificationMailApi?.loading
                    ) {
                        setVerified(true);
                    }
                }).catch( (err)=> {
                    setVerified(false);
                    if(process.env.REACT_APP_DEBUG !== undefined){
                        console.log(err);
                    }
                });
        };
    
        fetchData();
    }, []);    
    
    return (
        <>
        {verified === null ?
            null
            :
            verified === true
                ? <Outlet />
                : <Navigate to={APP_ROUTES.ERREURVERIFMAIL} state={{ from: location }} replace />
        }
        </>
    );
}

export default RequireVerification;