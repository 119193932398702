import React from 'react';
import { Box, Heading, Text, Button, Link as ChakraLink, Image, Flex } from '@chakra-ui/react';
import Footer from '../components/Footer';

function APropos() {
  return (
    <div className="accueil">
      {/* Ajustement de la hauteur et ajout de padding bottom pour éviter le chevauchement */}
      <Box 
        position="relative" 
        width="100%" 
        minHeight={["70vh", "80vh", "auto", "auto"]} 
        bg="gray.800" 
        pb={["100px", "100px", "150px", "150px"]} // Padding bottom pour laisser de l'espace avant le footer
        pt={[0, 0, "5%", "1%"]} // Padding bottom pour laisser de l'espace avant le footer
      >
        <Flex 
          ml={["5%", "5%", "10%", "10%"]} 
          mr={["5%", "5%", "10%", "10%"]} 
          flexDirection={["column", "column", "column", "row"]} 
          alignItems="center" 
          justifyContent="center" 
          color="white" 
          zIndex="10" 
          gap={8}  // Plus grand espacement entre les éléments
        >
          {/* Photo */}
          <Image 
            src="/photo.jpeg" 
            alt="Matvey Sienichev" 
            borderRadius="full" 
            boxSize={["150px", "200px", "250px", "300px"]} 
            objectFit="cover" 
            shadow="lg"
            mb={[4, 4, 0, 0]} // Marge inférieure pour les petits écrans
          />
          {/* Texte et Liens */}
          <Box textAlign={["center", "center", "left", "left"]} >
            <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} mb={4}>
              À propos de moi...
            </Heading>
            <Heading size='md' fontFamily="heading" fontSize={["md","xl","2xl","3xl"]} mb={4}>
              Matvey Sienichev, M1 Informatique, UBO Brest
            </Heading>
            <Text mb={4} fontSize={["sm","md","lg","xl"]}>
              En tant qu'étudiant passionné par l'informatique, j'ai entrepris ce projet pour mettre en pratique mes compétences et apprendre de nouvelles technologies. Ce site représente bien plus qu'une simple application ; il est le reflet de ma curiosité, de mon engagement et de mon désir de créer des solutions qui ont un impact. Chaque ligne de code écrite ici est une étape vers mon rêve de devenir un professionnel accompli dans le domaine des technologies de l'information.
            </Text>
            <Text mb={4}  fontSize={["sm","md","lg","xl"]}>
              Ce projet a également été l'occasion d'innover et de repousser les limites de ce qui est possible avec le développement web moderne. Je suis déterminé à poursuivre ce chemin, à apprendre et à grandir avec chaque défi rencontré.
            </Text>
            <ChakraLink href="https://www.linkedin.com/in/matvey-sienichev-5aa3572a9/" isExternal _hover={{ textDecoration: 'none' }}>
              <Button colorScheme="cyan" size="lg">
                Vers mon LinkedIn
              </Button>
            </ChakraLink>
          </Box>
        </Flex>
      </Box>
      <Footer />
    </div>
  );
}

export default APropos;
