import React from 'react';
import SuccessDiorama from '../components/SuccessDiorama';
import { Box, Heading, Flex } from '@chakra-ui/react';
import FacebookPostButton from '../components/FacebookShareButton';
import TwitterPostButton from '../components/TwitterShareButton';
import Footer from '../components/Footer';

function VerificationReussie({width}) {
	return (
    <div className="accueil">
      <Box position="relative" width="100%" height={["70vh", "80vh", "92vh", "92vh"]} >
        <SuccessDiorama width={width} />
        <Box position="absolute" top="0" left={["7%","7%","7%","20%"]} right="0" bottom={["7%","7%","7%","20%"]} display="flex" flexDirection="column" alignItems="left" justifyContent="center" color="white" zIndex="10">
          <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} textAlign="left">Félicitations!</Heading>
          <Heading size='md' fontFamily="title" fontSize={["4xl","5xl","6xl","7xl"]} textAlign="left">Votre adresse est validée</Heading>
          <Heading size='md' mt="4" fontFamily="heading" fontSize={["md","xl","2xl","3xl"]} textAlign="left">Vous recevrez des mails de rappel pour aller voter le 30 Juin et 7 Juillet 2024</Heading>
          <Flex 
            mt="4" 
            gap="2" 
            flexDirection={['column', 'column', 'row', 'row']} // Vertical on small screens, horizontal on large screens
            alignItems={['left', 'left', 'flex-start', 'flex-start']} // Centered on small screens, start aligned on larger screens
          >
            <FacebookPostButton />
            <TwitterPostButton />
          </Flex>
        </Box>
      </Box>
      <Footer />
    </div>
    );
}

export default VerificationReussie;