import React from 'react';
import EmailCard from '../components/EmailCard';
import { useAuth } from '../providers/AuthProvider';
import { Box, Heading, Container, Stack, Flex, Button, Link as ChakraLink } from '@chakra-ui/react';
import Footer from '../components/Footer';

function Inscription() {
	return (
    <div className="accueil">
      <Flex alignItems="center" justifyContent="center" >
        <Heading mt="16" mb="4">
          Rappel de vote          
        </Heading>
      </Flex>
      <Flex alignItems="center" justifyContent="center" >
        <EmailCard description="Pour sensibiliser à l'exercice démocratique, RappelVote vous propose des rappels pour aller voter le 30 Juin et le 7 Juillet 2024." />
      </Flex>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
    );
}

export default Inscription;