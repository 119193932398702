import { Box, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';

const FacebookShareButton = () => {
  const shareUrl = 'https://www.rappelvote.fr'; // The URL you want to share
  const redirectUri = 'https://www.rappelvote.fr'; // The URL to redirect after sharing

  const handleShare = () => {
    window.FB.ui({
      display: 'popup',
      method: 'share',
      href: shareUrl,
      hashtag: "#RappelVote"
    }, function (response) {
    }); 
  };

  useEffect( ()=> {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId            : '877089307577665',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v2.3'
      });
    };
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <Box display="flex" mt="4" fontSize={["sm","sm","md","xl"]} alignItems="left">
    <Button colorScheme="cyan" onClick={handleShare}>
      Partager sur Facebook
    </Button>
    </Box>
  );
};

export default FacebookShareButton;