export const API_ROUTES = {
  ADMIN: `/admin`,
  LOGIN: `/admin/login`,
  VERIFY: `/admin/verify`,
  ARTICLE: `/article`,
  THEME: `/theme`,
  INDIVIDUMAIL: `/individu`,
  VERIFICATION: `/individu/verification`
}

export const APP_ROUTES = {
  INSCRIPTION: `/inscription`,
  LOGIN: `/login`,
  ARTICLE: `/article`,
  THEME: `/theme`,
  INDIVIDUMAIL: `/individu`,
  VERIFICATION: `/verification/:token`,
  ERREURVERIFMAIL: `/verification/error`,
  APROPOS: `/apropos`
}

/*usage :  
  import { APP_ROUTES } from '../utils/constants';
  puis
  API_ROUTES.LOGIN , route de login 
  */