import client from "./client";
import { API_ROUTES } from '../utils/constants';
import { getToken } from '../utils/token';

const getIndividuMails = () => client.get(API_ROUTES.INDIVIDUMAIL, {headers: {'Authorization': `Bearer ${getToken()}`}});
const getIndividuMail = (id) => client.get(`${API_ROUTES.INDIVIDUMAIL}/${id}`);
const postIndividuMail = (data) => client.post(API_ROUTES.INDIVIDUMAIL, data);
const verificationMail = (token) => client.get(`${API_ROUTES.INDIVIDUMAIL}/verification/${token}`);
// const putAdmin = (id, data) => client.put(`${API_ROUTES.ADMIN}/${id}`, data);
// const deleteAdmin = (id) => client.delete(`${API_ROUTES.ADMIN}/${id}`);
// const verifyAdmin = (token) => client.get(API_ROUTES.VERIFY, {headers: {'Authorization': `Bearer ${token}`}});
// const loginAdmin = (data) => client.post(API_ROUTES.LOGIN, data);

// export default exports = {
const exports = {
  getIndividuMails,
  getIndividuMail,
  postIndividuMail,
  verificationMail
};

export default exports;