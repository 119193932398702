import { Box, Button } from '@chakra-ui/react';
import React from 'react';

const TwitterShareButton = () => {
  const shareUrl = 'https://www.rappelvote.fr'; // The URL you want to share
  const text = 'Le 30 Juin et 7 Juillet je vote!'; // The text you want to share

  const handleShare = () => {
    const url = `https://twitter.com/intent/tweet?` +
                `text=${encodeURIComponent(text)}` +
                `&url=${encodeURIComponent(shareUrl)}` +
                `&hashtags=RappelVote`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  return (
    <Box display="flex" mt="4" fontSize={["sm","sm","md","xl"]} alignItems="left">
      <Button colorScheme="cyan" onClick={handleShare}>
        Partager sur X
      </Button>
    </Box>
  );
};

export default TwitterShareButton;
